<template>
  <div>
    <a-card :title="$t('库位流水')">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker @change="changeDate" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <client-select v-model="searchForm.client" :placeholder="$t('货主')" @change="search"></client-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.type" :placeholder="$t('类型')" allowClear style="width: 100%" @change="search">
            <a-select-option key="modify" value="modify">{{ $t("人为修改") }}</a-select-option>
            <a-select-option key="batch_import" value="batch_import">{{ $t("批量导入") }}</a-select-option>
            <a-select-option key="shelve" value="shelve">{{ $t("上架") }}</a-select-option>
            <a-select-option key="void_shelve" value="void_shelve">{{ $t("作废上架") }}</a-select-option>
            <a-select-option key="picking" value="picking">{{ $t("拣货") }}</a-select-option>
            <a-select-option key="void_picking" value="void_picking">{{ $t("作废拣货") }}</a-select-option>
            <a-select-option key="transfer_in" value="transfer_in">{{ $t("移入") }}</a-select-option>
            <a-select-option key="void_transfer_in" value="void_transfer_in">{{ $t("作废移入") }}</a-select-option>
            <a-select-option key="transfer_out" value="transfer_out">{{ $t("移出") }}</a-select-option>
            <a-select-option key="void_transfer_out" value="void_transfer_out">{{ $t("作废移出") }}</a-select-option>
            <a-select-option key="stock_check" value="stock_check">{{ $t("盘点") }}</a-select-option>
            <a-select-option key="void_stock_check" value="void_stock_check">{{ $t("作废盘点") }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.material_number" :placeholder="$t('产品编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" :placeholder="$t('单号, 库位编号, 批次编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
        </a-col>
      </a-row>
      <a-row>
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ x: 1980 }"
          @change="tableChange"
        />
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { warehouseOption } from "@/api/option";
import { locationFlowList, locationFlowExport } from "@/api/flow";
import columns from "./columns.js";
import { exportExcel } from "@/utils/excel";
import moment from "moment";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],

      visible: false,
      targetItem: {},
      form: {},

      warehouseItems: [],
      materialItems: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.searchForm.warehouse = this.currentWarehouse;
      });
    },
    list() {
      this.loading = true;
      locationFlowList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    changeDate(date) {
      let startDate = date[0];
      let endDate = date[1];

      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    exportExcel() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      locationFlowExport(searchForm)
        .then((resp) => {
          exportExcel(resp, this.$t("库位流水"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
